import React from "react";

// These utilities are used to mark where translation should occur.

type Select = { default?: string } & {
  [key: string]: string;
  [key: number]: string;
};

export const select = (value: string | number, options: Select) => (
  options[value] || options.default || null
);

export const t = (phrase: string) => phrase;

type TransProps = {
  children: React.ReactNode;
};

export const Trans: React.FC<TransProps> = ({ children }) => <>{children}</>;
