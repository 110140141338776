import imagesSizes from "constants/imagesSizes";
import { Media, Story } from "chq";

export const getImageFromStory = (size: number, story: Story | {media: Media} |undefined) => {
  if (!story) return "";

  const { media } = story;

  if (media.mediaType === "video") {
    return media.thumbnail;
  }

  switch (size) {
    case imagesSizes.THUMB:
      return media.thumbnail || media.url;
    case imagesSizes.SMALL:
      return media.smallUrl || media.url;
    case imagesSizes.NORMAL:
      return media.url;
    case imagesSizes.FULL:
      return media.fullUrl || media.url;
    case imagesSizes.SHAREABLE:
      return media.shareableUrl || media.url;
    default:
      return "";
  }
};

export default getImageFromStory;
