const getUniqueUsers = item => {
  if (!item) {
    return [];
  }

  const users = item.users || [];
  // Primary User ID
  const primary = users[0]?.id;

  // Child User IDs
  const children = item.childUserActivities.map(
    story => story.entity?.creator?.id
  );

  // Unique list of all users on this activity
  const userIds = Array.from(new Set([primary, ...children]));

  return userIds;
};

export default getUniqueUsers;
