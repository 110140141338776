/* eslint-disable filenames/no-index, filenames/match-exported */

import "react-app-polyfill/ie11";
import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { Loader } from "@culturehq/components";
import client, { isSignedIn } from "@culturehq/client";
import { firebaseConfig, rumConfig } from "app/utils";
import { getToken } from "app/utils/firebaseConfig";
import serviceWorker from "./serviceWorker";

import "./styles/app.scss";

const App = React.lazy(() => import(/* webpackChunkName: "app" */ "./components/App"));

const Root = () => (
  <StrictMode>
    <Suspense fallback={<Loader loading />}>
      <App />
    </Suspense>
  </StrictMode>
);

ReactDOM.render(<Root />, document.getElementById("root"));
firebaseConfig();
rumConfig();
if (isSignedIn()) {
  getToken()
    .then(firebaseToken => {
      client.createRegistrationToken({ token: firebaseToken, device: "web" })
        .then(_ => {})
        .catch(_ => {});
    }).catch(_ => {});
}
serviceWorker();

// eslint-disable-next-line no-console
console.log(
  "%cHi friend! If you're having trouble, feel free to email us at %csupport@culturehq.com.",
  `
    background: url(https://assets.culturehq.com/CultureHQ-logo.png);
    background-position: 0 10px;
    background-repeat: no-repeat;
    background-size: 64px 64px;
    line-height: 64px;
    padding: 10px 0 10px 74px;
  `,
  "font-weight: bold"
);

export default Root;
