import Unsplash, { toJson } from "unsplash-js";

import { UnsplashImageResponse } from "chq";

import { UNSPLASH_DEFAULT_IMAGE } from "./constants";

export const downloadPhoto = async (selectedImage: UnsplashImageResponse) => {
  if (selectedImage.links?.download_location) {
    await unsplash.photos.downloadPhoto({
      links: { download_location: selectedImage.links.download_location }
    });
  }
};

export const getUnsplashRandomImage = async (tag: string) => {
  try {
    const response = await unsplash.photos.getRandomPhoto({ featured: true, query: tag });
    const json = await toJson(response);
    if (json.errors?.length > 0) return UNSPLASH_DEFAULT_IMAGE;
    return json;
  } catch (error) {
    return UNSPLASH_DEFAULT_IMAGE;
  }
};

const unsplash = new Unsplash({
  accessKey: "lH31SuTgtuA1gAFlireA6iYQZ6YRSrcn_1hzW_N4qVE",
  secret: "e4Zbv7wB9wxoOlZ9SyqDaVyUs7HB6VlsjGUlBfU89Mo"
});

export default unsplash;
