import Rollbar from "rollbar/dist/rollbar.umd";

const devErrorService = {
  error: error => {
    devErrorService.errors.push(error);
    console.log(error); /* eslint no-console: off */
  },
  log: log => {
    devErrorService.errors.push(log);
    console.log(log); /* eslint no-console: off */
  },
  errors: []
};

const errorService = process.env.NODE_ENV !== "production" ? devErrorService : new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  captureUncaught: true,
  payload: { environment: process.env.NODE_ENV }
});

export default errorService;
