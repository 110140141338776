/* eslint-disable no-undef */
import firebase from "firebase/app";
import "firebase/messaging";

export const getToken = () => new Promise((resolve, reject) => {
  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(() => messaging.getToken())
    .then(firebaseToken => {
      resolve(firebaseToken);
    }).catch(_ => {
      reject();
    });
});

export const onMessageListener = () => new Promise(resolve => {
  const messaging = firebase.messaging();
  messaging.onMessage(payload => {
    resolve(payload);
  });
});

export const firebaseConfig = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyDdRurJxn-EF9vHoLRc1LipSH-wx3c_EP8",
    authDomain: "culturehq-ae895.firebaseapp.com",
    projectId: "culturehq-ae895",
    storageBucket: "culturehq-ae895.appspot.com",
    messagingSenderId: "227990763613",
    appId: "1:227990763613:web:461779550eec813a6835b4",
    measurementId: "G-NH2N71YWM0"
  });
};

export default firebaseConfig;
