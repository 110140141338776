const groupBy = <K, V>(items: V[], iteratee: (value: V) => K): Map<K, V[]> => (
  items.reduce((map, value) => {
    const key = iteratee(value);

    let array = map.get(key);
    if (!array) {
      array = [];
      map.set(key, array);
    }

    array.push(value);

    return map;
  }, new Map<K, V[]>())
);

export default groupBy;
