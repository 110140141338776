const arePostsEnabled = profile => {
  const {
    postsEnabled,
    userCreatedPostsEnabled
  } = profile.organization;

  return (postsEnabled && (profile.organizationAdmin || userCreatedPostsEnabled));
};

export default arePostsEnabled;
