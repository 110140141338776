/* eslint-disable no-undef */
import { datadogRum } from "@datadog/browser-rum";

export const rumConfig = () => {
  datadogRum.init({
    applicationId: "16e1c9e9-766b-4d86-8643-7c9ef36b10c1",
    clientToken: "pub4837518d5ac473e82e77025d07044204",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "culturehq-webapp",
    env: "production",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    beforeSend: (_event, _context) => {
      if ((process.env.NODE_ENV === "development") || (window.location.host.split(".")[0] === "platform-staging")) {
        return false;
      }
      return true;
    }
  });
};

export default rumConfig;
