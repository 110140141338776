const getSearchValue = storyPrompt => {
  if (storyPrompt) {
    // Regular expression to match emoji
    const emojiRegexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    const tags = storyPrompt.storyQuestionTags.filter(
      tag => !emojiRegexExp.test(tag.entity.name)
    );
    if (tags.length > 0) {
      return tags[0].entity.name;
    }
    return storyPrompt.question;
  }
  return undefined;
};

export default getSearchValue;
