export const getDuration = (seconds: number | undefined) => {
  if (seconds === Infinity) {
    return "00:00";
  }

  if (seconds) {
    if (seconds > 3000) {
      return new Date(1000 * seconds).toISOString().substr(11, 8);
    }
    return new Date(1000 * seconds).toISOString().substr(14, 5);
  }

  return "00:00";
};

export default getDuration;
