const getQuestionsOptions = ({ fetching, storyQuestions }) => {
  if (!fetching) {
    return storyQuestions.map(({ id, question }) => (
      { label: question, value: id }
    ));
  }
  return [];
};

export default getQuestionsOptions;
