const parseQueryString = (queryString: string): { [key: string]: string } => {
  if (queryString.length === 0) {
    return {};
  }

  return queryString.slice(1).split("&").reduce((accum, assign) => {
    const [key, value] = assign.split("=");
    return { ...accum, [key]: value };
  }, {});
};

export default parseQueryString;
