const serviceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then(registration => registration?.scope)
      .catch(err => err);
  }
};

export default serviceWorker;
