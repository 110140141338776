const removeQueryString = (queryString: string): any => {
  if (queryString.length === 0) {
    return [];
  }

  return queryString.slice(1).split("&").filter(element => {
    const [key, value] = element.split("=");
    return (key !== "action" && value !== "invite");
  });
};

export default removeQueryString;
