import imagesSizes from "constants/imagesSizes";

export const getImage = (size: number, image: any) => {
  if (typeof (image) === "string") {
    return image;
  }

  switch (size) {
    case imagesSizes.THUMB:
      // This is for the profile image, in this case is
      // better to use the medium not the thumb
      if (image?.mediumUrl) {
        return image.mediumUrl;
      }

      // From unsplash?
      if (image?.urls?.thumb) {
        return image.urls.thumb;
      }

      return image?.thumbUrl || image?.mediaUrl || image?.url;
    case imagesSizes.SMALL:
      // From unsplash?
      if (image?.urls?.small) {
        return image.urls.small;
      }

      return image?.smallUrl || image?.mediaUrl || image?.url;
    case imagesSizes.NORMAL:
      // From unsplash?
      if (image?.urls?.regular) {
        return image.urls.regular;
      }

      return image?.mediumUrl || image?.mediaUrl || image?.url;
    case imagesSizes.FULL:
      // From unsplash?
      if (image?.urls?.full) {
        return image.urls.full;
      }

      return image?.fullUrl || image?.mediaUrl || image?.url;
    default:
      return "";
  }
};

export default getImage;
