/* eslint no-restricted-syntax:off */
/* eslint guard-for-in:off */

const deepClone = <T>(value: T): T => {
  if (typeof value !== "object" || value === null) {
    return value;
  }

  const target: any = Array.isArray(value) ? [] : {};

  for (const key in value) {
    target[key] = deepClone(value[key]);
  }

  return target;
};

export default deepClone;
