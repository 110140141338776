import { StoryQuestion } from "chq";

const getTrend = (storyQuestion: StoryQuestion): string => {
  if (storyQuestion.storyQuestionType === "child_prompt" && storyQuestion.parentStoryQuestion) {
    return storyQuestion.parentStoryQuestion.question;
  }

  return storyQuestion.question;
};

export default getTrend;
