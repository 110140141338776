import { Organization } from "chq";

const getOrganizationSubdomain = (organization: Organization) => (
  organization.name.replace(/\s+/g, "-")
    .replaceAll("'", "")
    .replaceAll("-+-", "and")
    .replaceAll("Activision-Blizzard", "activisionblizzard")
    .replaceAll("Talent-Acquisition-Week", "talentacquisitionweek")
    .replaceAll("West-Monroe", "westmonroe")
    .toLowerCase()
);

export default getOrganizationSubdomain;
