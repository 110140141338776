const containedBy = (element: any, className: string): boolean => {
  if (!element) {
    return false;
  }
  if (element.classList && element.classList.contains(className)) {
    return true;
  }
  return containedBy(element.parentNode, className);
};

export default containedBy;
