const getFiltersOptions = (key, array, options) => {
  const result = [];
  if (options && options[key]) {
    options[key].forEach(
      entityId => result.push(array[entityId])
    );
  }

  return result;
};

export default getFiltersOptions;
