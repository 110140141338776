const areEventsEnabled = profile => {
  const {
    eventsEnabled,
    userCreatedEventsEnabled
  } = profile.organization;

  return ((profile.organizationAdmin || userCreatedEventsEnabled) && eventsEnabled);
};

export default areEventsEnabled;
