import tagsTypes from "constants/tagsTypes";

export const getFilledIcon = (category: string) => {
  switch (category) {
    case tagsTypes.CONNECTION:
      return "person";
    case tagsTypes.INTEREST:
      return "heart";
    case tagsTypes.LOCATION:
      return "ios-location";
    case tagsTypes.COMPANY:
      return "building-filled";
    case tagsTypes.DEPARTMENT:
      return "ios-people";
    case tagsTypes.SKILL:
      return "hammer-filled";
    case tagsTypes.CLASS_YEAR:
      return "clock-filled";
    case tagsTypes.CLUB:
      return "ios-people";
    case tagsTypes.PROGRAM:
      return "star";
    case tagsTypes.DEGREE:
      return "mortarboard-filled";
    default:
      break;
  }
  return "";
};

export default getFilledIcon;
