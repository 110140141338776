const processEntities = (entities, key) => {
  const tags = {};
  if (entities) {
    entities.forEach(tag => {
      tags[`${key}-${tag.id}`] = {
        ...tag,
        type: key
      };
    });
  }
  return tags;
};

export const getTags = (organizationValues, interests, skills, classYears, clubs) => {
  const tags = {
    ...processEntities(organizationValues, "organization values"),
    ...processEntities(interests, "interests"),
    ...processEntities(skills, "skills"),
    ...processEntities(classYears, "classYears"),
    ...processEntities(clubs, "clubs")
  };

  return Object.values(tags).sort((a, b) => {
    if (a.number === b.number) {
      return 0;
    }

    return a.number > b.number ? -1 : 1;
  });
};

export default getTags;
