type ValidDomains = {
  [key: string]: string[];
};

const VALID_DOMAINS: ValidDomains = {
  rtx: ["collins.com", "prattwhitney.com", "raytheon.com", "rtx.com"],
  synopsys: ["synopsys.com"]
};

const checkOrgAllowedDomain = (organizationName: string, email: string): boolean => {
  const domain = email.split("@")[1];
  if (!domain) return false;
  if (!(organizationName.toLowerCase() in VALID_DOMAINS)) return true;

  return VALID_DOMAINS[organizationName.toLowerCase()].includes(domain.toLowerCase());
};

export default checkOrgAllowedDomain;
